"use client";

import { create } from "zustand";

type State = {
  isNavigating: boolean;
};

type Actions = {
  setIsNavigating: (isNavigating: boolean) => void;
};

export const useNavigationStore = create<State & Actions>((set) => ({
  isNavigating: false,
  setIsNavigating: (isNavigating: boolean) =>
    set((state) => ({ ...state, isNavigating: isNavigating }))
}));
