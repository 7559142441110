import { ReactElement } from "react";
import random from "lodash/random";

import { getUtcDateFromString } from "./utcDateFromString";

const decamelize = (str: string, separator?: string) => {
  separator = typeof separator === "undefined" ? "_" : separator;

  return str
    .replace(/([a-z\d])([A-Z])/g, "$1" + separator + "$2")
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, "$1" + separator + "$2")
    .toLowerCase();
};

export const toHtmlId = (value: string | ReactElement) => {
  return typeof value === "string" ? value.replace(/\s+/g, "-") : random().toString();
};

export const humanize = (string: string) => {
  string = decamelize(string);
  string = string
    .toLowerCase()
    .replace(/[_-]+/g, " ")
    .replace(/\s{2,}/g, " ")
    .trim();
  string = string.charAt(0).toUpperCase() + string.slice(1);

  return string;
};

export const titleize = (str: string) => {
  // Exclude articles and such from capitalization
  const regex = /(^|\b(?!(and|at|the|for|to|but|by|of|or|nor|for|so|yet)\b))\w+/g;
  return str.toLowerCase().replace(regex, (s: string) => s[0]?.toUpperCase() + s.slice(1));
};

export const getYearFromIsoString = (str: string | undefined) => {
  if (!str) return undefined;
  try {
    return (getUtcDateFromString(str) ?? undefined)?.getFullYear();
  } catch {
    return undefined;
  }
};

export const utcSecondsToDate = (utcSeconds: number): Date => {
  const d = new Date(0);
  d.setUTCSeconds(utcSeconds + d.getTimezoneOffset() * 60 * 1000);
  return d;
};

export const joinWithElement = (
  children: Array<JSX.Element>,
  renderJoin: (index: number) => JSX.Element
): JSX.Element[] =>
  children.reduce(
    (acc, item, index, arr) =>
      index >= arr.length - 1 ? [...acc, item] : [...acc, item, renderJoin(index)],
    [] as JSX.Element[]
  );
