"use client";

import { useEffect, useState } from "react";
import { usePathname } from "next/navigation";

import { useNavigationStore } from "common/state/useNavigationStore";

export const useDetectUrlUpdate = () => {
  const { setIsNavigating, isNavigating } = useNavigationStore((store) => store);
  const [currentPathname, setCurrentPathname] = useState<string | null>();
  const pathname = usePathname();

  useEffect(() => {
    if (isNavigating === false) return;
    return () => {
      if (isNavigating === true && pathname !== currentPathname) setIsNavigating(false);
      setCurrentPathname(pathname);
    };
  }, [currentPathname, isNavigating, pathname, setIsNavigating]);
};

export const UrlPathMonitoring = () => {
  useDetectUrlUpdate();
  return <></>;
};
