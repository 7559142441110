import React from "react";
import HelpOutlineOutlined from "@mui/icons-material/HelpOutlineOutlined";
import { Stack, Typography } from "@mui/material";
import compact from "lodash/compact";

import { MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import { logError } from "common/util/consoleHelpers";
import { formatWeekDate, SpecialDateFormat } from "common/util/formatHelpers";

import { InfoIcon } from "../InfoIcon";

export interface ReportDatesProps {
  generatedDate?: Date;
  generatedDateText?: string;
  dataDate?: Date;
  dataDateText?: string;
  updatedDate?: Date;
  updatedDateText?: string;
  granularity?: MhcTimeSeriesGranularityEnum;
}

export const formatDateTime = (
  date?: Date,
  granularity?: MhcTimeSeriesGranularityEnum | undefined,
  overrideYearFormat?: Intl.DateTimeFormatOptions["year"],
  overrideMonthFormat?: Intl.DateTimeFormatOptions["month"],
  specialFormat?: SpecialDateFormat
) => {
  if (!date) {
    return undefined;
  }
  try {
    const dateTimeOptions: Intl.DateTimeFormatOptions = {
      year: overrideYearFormat ? overrideYearFormat : "numeric",
      day: "numeric",
      month: "numeric",
      timeZone: "UTC"
    };
    if (
      granularity === MhcTimeSeriesGranularityEnum.Year ||
      granularity === MhcTimeSeriesGranularityEnum.FiveYearWindow
    ) {
      dateTimeOptions.day = undefined;
      dateTimeOptions.month = undefined;
    }
    if (granularity === MhcTimeSeriesGranularityEnum.Month) {
      dateTimeOptions.month = overrideMonthFormat ?? "long";
      dateTimeOptions.day = undefined;
    }
    if (granularity === MhcTimeSeriesGranularityEnum.Week) {
      return formatWeekDate({ value: date, specialFormat });
    }
    const formatter = new Intl.DateTimeFormat("en-US", dateTimeOptions);
    return formatter.format(date);
  } catch (error) {
    logError(error, `Error formatting date on report dates`, `Date given: ${date.toUTCString()}`);
    return undefined;
  }
};

const ReportDates: React.FC<ReportDatesProps> = ({
  generatedDate,
  generatedDateText = "Report generated on",
  dataDate,
  dataDateText = "Data are current as of",
  updatedDate,
  updatedDateText = "Most recent dashboard update",
  granularity
}) => {
  const parsedGeneratedDate = formatDateTime(generatedDate);
  const parsedDataDate = formatDateTime(dataDate, granularity);
  const parsedUpdatedDate = formatDateTime(updatedDate, "day");
  // Data current as of February 9, 2023
  // Last updated February 10, 2023
  const nonNullCount = compact([parsedGeneratedDate, parsedDataDate, parsedUpdatedDate]).length;
  return (
    <Stack direction="row" gap={1} alignItems="flex-end">
      <Stack
        sx={{
          alignItems: {
            xs: "flex-start",
            md: "flex-end"
          },
          textAlign: { xs: "left", md: "right" },
          flex: { xs: 0, md: 1 }
        }}
      >
        {parsedGeneratedDate !== undefined && (
          <Typography
            variant="caption"
            component={"p"}
            sx={({ palette }) => ({ color: palette.grey[700], whiteSpace: "nowrap" })}
          >
            {generatedDateText} {parsedGeneratedDate}
          </Typography>
        )}
        {parsedDataDate !== undefined && (
          <Typography
            variant="caption"
            component={"p"}
            sx={({ palette }) => ({ color: palette.grey[700], whiteSpace: "nowrap" })}
          >
            {dataDateText} {parsedDataDate}
          </Typography>
        )}
        {parsedUpdatedDate !== undefined && (
          <Typography
            variant="caption"
            component={"p"}
            sx={({ palette }) => ({ color: palette.grey[700], whiteSpace: "nowrap" })}
          >
            {updatedDateText}: {parsedUpdatedDate}
          </Typography>
        )}
      </Stack>
      {nonNullCount > 1 && (
        <InfoIcon IconComponent={HelpOutlineOutlined}>
          <Stack direction="column" gap={1}>
            <Typography variant="body2">Why are these dates different?</Typography>
            <Typography variant="body2">
              To ensure data accuracy and quality, all data received by DPH is thoroughly reviewed
              before publishing.
            </Typography>
          </Stack>
        </InfoIcon>
      )}
    </Stack>
  );
};

export default ReportDates;
