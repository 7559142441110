"use client";

// Most custom colors are maintained in "theme/colors.ts"

import { Open_Sans } from "next/font/google";
import { grey } from "@mui/material/colors";
import { createTheme, responsiveFontSizes, Theme } from "@mui/material/styles";

import {
  additionalPalette,
  brandPalette,
  countyColors,
  DataColors,
  demographicColors,
  DemographicKeys,
  geographyColors,
  linkColor
} from "./colors";
import { themeComponentsConfiguration } from "./ThemeComponentsConfiguration";

type ResponsiveStyleValue = string | number;
export type ResponsiveStyle =
  | ResponsiveStyleValue
  | {
      xs?: ResponsiveStyleValue;
      sm?: ResponsiveStyleValue;
      md?: ResponsiveStyleValue;
      lg?: ResponsiveStyleValue;
      xl?: ResponsiveStyleValue;
    };

const openSans = Open_Sans({
  subsets: ["latin"],
  style: ["normal", "italic"],
  weight: ["300", "400", "600", "700", "800"],
  display: "swap"
});
const fontFamily = `${openSans.style.fontFamily}, sans-serif`;

export const primaryColor = "#0260f0";

declare module "@mui/material/styles" {
  type DefaultTheme = Theme;
  interface TypographyVariants {
    body3: React.CSSProperties;
    body4: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
  }

  interface Palette {
    brand: Palette["primary"] & { contrastBlue: string };
    brandSecondary: Palette["primary"];
    borders: {
      light: string;
      main: string;
      dark: string;
    };
    dropdowns: Palette["primary"];
    nav: Palette["primary"];
    light: Record<"secondary" | "primary", string>;
    dark: Record<string, string>;
    component: Record<string, string>;
    demographics: Record<DemographicKeys, Record<string, string>>;
    data: DataColors;
  }
  interface PaletteOptions {
    brand: PaletteOptions["primary"] & { contrastBlue: string };
    brandSecondary: PaletteOptions["primary"];
    borders: Record<string, string>;
    nav: PaletteOptions["primary"];
    dropdowns: PaletteOptions["primary"];
    light: Record<"secondary" | "primary", string>;
    dark: Record<string, string>;
    component: Record<string, string>;
    demographics: Record<DemographicKeys, Record<string, string>>;
    data: DataColors;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    brand: true;
    brandSecondary: true;
    dark: true;
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    brand: true;
    brandSecondary: true;
    dark: true;
  }
}

const { brand, brandSecondary, success } = brandPalette;
const { component } = additionalPalette;

// Create a theme instance.
const altTheme = createTheme({
  components: { ...themeComponentsConfiguration },
  palette: {
    action: {
      disabled: "#6C757D",
      disabledBackground: "rgba(0, 0, 0, .04)"
    },
    borders: {
      light: "#E5E5E5",
      main: "rgba(0, 0, 0, 0.23)",
      dark: "rgba(0, 0, 0, 0.5)"
    },
    brand: {
      ...brand,
      contrastText: "#FFFFFF",
      contrastBlue: "#2669aa"
    },
    brandSecondary,
    component,
    dark: {
      light: grey["300"],
      main: grey["700"],
      dark: grey["900"],
      contrastText: "#FFFFFF"
    },
    data: {
      geography: geographyColors,
      county: countyColors
    },
    demographics: demographicColors,
    dropdowns: {
      main: linkColor
    },
    light: {
      secondary: "rgb(0,0,0,0.54)",
      primary: "rgb(0,0,0,0.87)"
    },
    nav: {
      main: brand.main,
      light: "#495057"
    },
    primary: {
      //main: "#1576d7"
      main: primaryColor
    },
    secondary: {
      ...brand
    },
    success,
    warning: {
      main: "#FF9800"
    }
  },
  shape: {
    borderRadius: 4
  },
  typography: {
    fontWeightBold: 700,
    fontWeightMedium: 600,
    fontWeightLight: 300,
    fontFamily,
    h1: {
      fontSize: 38,
      fontWeight: 300,
      lineHeight: 1.2
    },
    h2: {
      fontSize: 32,
      fontWeight: 400,
      lineHeight: 1.125
    },
    h3: {
      fontSize: 25,
      fontWeight: 400,
      lineHeight: 1.36
    },
    h4: {
      fontSize: 20,
      fontWeight: 400,
      lineHeight: 1.334
    },
    h5: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: 1.38888889
    },
    h6: {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: 1.3
    },
    body1: {
      fontSize: 16,
      lineHeight: "26px",
      fontFamily
    },
    body2: {
      fontSize: "14px",
      lineHeight: "22px",
      fontFamily
    },
    body3: {
      fontSize: "12px",
      lineHeight: "16px",
      fontFamily
    },
    body4: {
      fontSize: "10px",
      lineHeight: "14px",
      fontFamily
    },
    caption: {
      fontSize: "12px",
      lineHeight: "16px",
      fontWeight: 400,
      fontFamily
    }
  }
});

export const theme = responsiveFontSizes(altTheme);
