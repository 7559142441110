import isNil from "lodash/isNil";

import { MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import { formatDateTime } from "common/components/ReportDates/ReportDates";

export const normalizeDate = (date: Date) =>
  new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000));

export const getUtcDateFromString = (
  date: string | number | null | undefined | Date
): Date | null => {
  if (isNil(date)) {
    return null;
  }
  const parsedDate = date ? normalizeDate(new Date(date)) : undefined;
  const dateTimeOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    day: "numeric",
    month: "numeric",
    timeZone: (parsedDate?.getTimezoneOffset() ?? 0) < 0 ? "UTC" : undefined,
    hour: "numeric",
    minute: "numeric"
  };
  const formatter = new Intl.DateTimeFormat("en", dateTimeOptions);
  return new Date(formatter.format(parsedDate));
};

export const getUtcDateNumber = (date: string | number | null | undefined): number | null => {
  if (isNil(date)) {
    return null;
  }
  const parsedDate = date ? new Date(date) : undefined;
  const dateTimeOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    day: "numeric",
    month: "numeric",
    timeZone: (parsedDate?.getTimezoneOffset() ?? 0) < 0 ? "UTC" : undefined
  };
  const formatter = new Intl.DateTimeFormat("en", dateTimeOptions);
  const finalDate = new Date(formatter.format(parsedDate)).getTime();
  return finalDate;
};

export const formatDateFromUTC = (
  date: string | number | null | Date | undefined,
  granularity?: MhcTimeSeriesGranularityEnum,
  yearOverride?: Intl.DateTimeFormatOptions["year"],
  monthOverride?: Intl.DateTimeFormatOptions["month"]
): string | null => {
  if (date !== 0 && isNil(date)) {
    return null;
  }
  const parsedDate = date === 0 || date ? normalizeDate(new Date(date)) : undefined;
  return formatDateTime(parsedDate, granularity, yearOverride, monthOverride) ?? null;
};
